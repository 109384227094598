import React, { Component } from 'react';
import logo from './logo.svg';
import firebase from 'firebase';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Dashboard from './Dashboard'
import './App.css';


// Firebase App is always required and must be first


// Comment out (or don't require) services that you don't want to use
// require("firebase/storage");

var config = {
  apiKey: "AIzaSyBUyXtSYrWtjkmSbW3ZGwHC5Vj6Sb3NT3M",
  authDomain: "pdfmang.firebaseapp.com",
  databaseURL: "https://pdfmang.firebaseio.com",
  projectId: "pdfmang",
  storageBucket: "pdfmang.appspot.com",
  messagingSenderId: "512387639614"
};
firebase.initializeApp(config);
var db = firebase.firestore();
const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: '/dashboard',
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
  ]
};

class SignInScreen extends React.Component {
  render() {
    return (
      <div>
        <h1>My App</h1>
        <p>Please sign-in:</p>
        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()}/>
      </div>
    );
  }
}

class App extends Component {
  render() {
    return (
      <Router>
        <div className="App">
        <Route exact path="/login" component={SignInScreen} />
        <Route exact path='/dashboard' render={(props)=><Dashboard {...props} db={db}></Dashboard>} />
        </div>
      </Router>
    );
  }
}

export default App;
