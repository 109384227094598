import React, { Component } from 'react';
import firebase from 'firebase';
var config = {
    apiKey: "AIzaSyBUyXtSYrWtjkmSbW3ZGwHC5Vj6Sb3NT3M",
    authDomain: "pdfmang.firebaseapp.com",
    databaseURL: "https://pdfmang.firebaseio.com",
    projectId: "pdfmang",
    storageBucket: "pdfmang.appspot.com",
    messagingSenderId: "512387639614"
  };
  //firebase.initializeApp(config);

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
          user: '',
          inStockOnly: false
        };
        firebase.auth().onAuthStateChanged((user)=> {
            if (user) {
              this.setState({user: user})
            } else {
              // No user is signed in.
            }
        });
        let query = props.db.collection("mangas")
        query.onSnapshot((snapshot)=>{
            snapshot.docChanges().forEach(function(change) {
                if (change.type === 'removed') {
                    console.log('removed '+ change.doc.id)
                  } else {
                    console.log(change.type)
                    console.log(change.doc.id);
                  }
                })})
        
        
        
      }
    render(){
        var user = 'User';
       
        return(
            <h1>Hi, {this.state.user.displayName}</h1>
        )
        
    }
    
}
export default Dashboard;
